import React, { useEffect, useState } from 'react';
import { LayoutContext } from 'frontastic/provider/layout';
export interface AmplienceSpacerProps {
  content: {
    width: {
      desktop: number;
      tablet: number;
      mobile: number;
    };
    backgroundColor: string;
  };
}

const AmplienceSpacer: React.FC<AmplienceSpacerProps> = ({ content }) => {
  const layout = React.useContext(LayoutContext);
  const [currentScreenWidth, setCurrentScreenWidth] = useState<string>('mobile');
  useEffect(() => {
    if (layout.isDesktop || layout.isLaptop) {
      setCurrentScreenWidth('desktop');
    } else if (layout.isTablet) {
      setCurrentScreenWidth('tablet');
    } else {
      setCurrentScreenWidth('mobile');
    }
  }, [layout.isDesktop, layout.isMobile, layout.isTablet, layout.isLaptop]);
  return (
    <div
      data-testid="amplience-spacer"
      style={{ backgroundColor: content.backgroundColor, height: content.width[currentScreenWidth] }}
    ></div>
  );
};

export default AmplienceSpacer;
